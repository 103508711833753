<template>
  <div>
    <cElementEdit v-show="selectedElementId" v-model="selectedElementId" :elementkey="elementkey" />
    <transition name="fade">
      <div v-show="showNotice" class="notice-container">
        <div class="notice" v-for="r in records" :key="r.Id">
          <span v-if="isAuthenticated" @click="editElement(r.Id)">Edit</span>
          <div class="notice-title" v-if="r.Title.length > 0">{{ r.Title }}</div>
          <div class="notice-subtitle" v-if="r.SubTitle.length > 0">{{ r.SubTitle }}</div>
          <div class="notice-content" v-if="r.Content.length > 0" v-html="r.Content"></div>
          <a :href="r.URL" v-if="r.URL.length > 0" target="_blank">More Information</a>
        </div>
        <div class="pull-right" style="color:#4f8db3" @click="showNotice = false">close</div>
      </div>
    </transition>
    <button v-show="isAuthenticated" class="btn" style="margin-top:10px;" @click="addElement">Add Notice</button>
  </div>
</template>

<script>
import * as _publicService from "@/services/publicService";
import * as _utility from "@/utility";
import cElementEdit from "@/components/cElementEdit.vue";

export default {
  name: "cElementNotices",
  components: { cElementEdit },
  props: ["elementkey"],
  data: () => ({
    records: [],
    loading: false,
    selectedElementId: null,
    showNotice: false,
  }),
  methods: {
    load() {
      this.loading = true;
      _publicService.getRecordsForElement(this.elementkey).then((response) => {
        if (!response.Success) {
          alert("there was a problem getting recrods for " + this.key);
        } else {
          this.records = response.Records;
          if (this.records.length > 0) this.showNotice = true;
        }
        this.loading = false;
      });
    },
    addElement() {
      if (!this.isAuthenticated) return;
      this.selectedElementId = _utility.emptyGuid;
    },
    editElement(id) {
      if (!this.isAuthenticated) return;
      this.selectedElementId = id;
    },
  },
  computed: {
    isAuthenticated: function() {
      return this.$store.getters.isAuthenticated();
    },
  },
  watch: {
    selectedElementId: function(newVal, oldVal) {
      if (oldVal && newVal == null) this.load();
    },
  },
  mounted: function() {
    this.load();
  },
};
</script>

<style scoped>
.notice-container {
  position: fixed;
  top: 5%;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background-color: rgb(236, 236, 236);
  padding: 30px 30px 10px 30px;
  border: 1px solid gray;
  border-radius: 10px;
  z-index: 100;
  overflow-y: auto;
}
.notice {
  margin-bottom: 20px;
}
.notice-title {
  font-family: "Open Sans", sans-serif;
  color: #4f8db3;
  font-size: 1.5em;
}
.notice-subtitle {
  font-family: "Open Sans", sans-serif;
  color: #53555c;
  margin-bottom: 10px;
}
</style>
