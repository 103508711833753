<template>
	<div>
		<div id="special-notice" class="modal fade" tabindex="-1" role="dialog">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
						<h4 class="modal-title">Special Notice</h4>
					</div>
					<div class="modal-body">
						<p>
							<b>Resumption of Water Service Late Fees and Terminations for Non-Payment</b>
						</p>
						<p>
							Beginning in March, Horsepen Bayou M.U.D. Board of Directors voted to suspend late fees and water
							terminations to help residents and businesses during the COVID-19 pandemic. The District has resumed
							applying the 10% LATE FEE on unpaid balances and will resume water service terminations for non-payment.
							If you are unable to pay your water bill, contact us about a payment plan to pay down your balance. To
							make a payment plan request, contact Customer Care at 281-290-6507.
						</p>
						<p>
							<b>We encourage you to pay what you can, to avoid accruing a large balance.</b>
						</p>
					</div>
				</div>
				<!-- /.modal-content -->
			</div>
			<!-- /.modal-dialog -->
		</div>
		<!-- /.modal -->
		<cElementNotices elementkey="Notices" />

		<!-- Homepage Slider -->
		<div class="welcome container">
			<div class="bg4 animate-in">
				<!-- Slide Title -->
				<h2 class="w-title">Welcome</h2>
				<!-- Slide Text -->
				<div class="w-subtitle">
					<div calss="row">
						<div class="col-md-8">
							Horsepen Bayou Municipal Utility District of Harris County, Texas was created as a water control and
							improvement district on June 18, 1969 by an act of the 61st Legislature, as a conservation and reclamation
							district pursuant to the authority of section 59 of Article XVI of the Constitution of Texas. By order of
							the Texas Water Commission, dated March 6, 1979, the District was converted to a municipal utility
							district. The District operates under the authority of Chapters 49 and 54 of the Texas Water Code.
						</div>
						<div class="col-md-4">
							<div class="callout" style="min-height: 200px">
								New Information!<br />
								<cElements elementkey="NewInformation" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="section">
			<div class="container">
				<div class="row">
					<div class="col-md-12 col-sm-12">
						<div class="in-press">
							The District contains approximately 551.7836 acres (but who's counting?) located 16 miles northwest of the
							central business district of the City of Houston, at the intersection of Eldridge Road and West Little
							York Road. The District was created to provide water, sewer, and drainage service to the land within the
							District. The District owns two water plants, one waste water treatment plant, and all the underground
							water, sewer, and drainage facilities required to serve the District. The District utility construction
							was financed by the sale of 5 bond issues. Horsepen Bayou MUD is governed by a five member Board of
							Directors elected to 4 year terms.
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12 col-sm-12">
						<div class="in-press">
							The District generally meets on the fourth Thursday at Noon at the offices of Allen Boone Humphries
							Robinson LLP, 3200 Southwest Freeway, Suite 2600, Houston, Texas 77027. Specific meeting date/time and
							location is included on meeting agendas (posted on the <a href="/calendar">calendar</a> page).
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12 col-sm-12">
						<div class="in-press">
							Residents of the District have the right to request the designation of a meeting location within the
							District under Section 49.062, Water Code. A description of this process can be found at
							<a href="https://www.tceq.texas.gov/waterdistricts">https://www.tceq.texas.gov/waterdistricts</a>.
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12 col-sm-12">
						<div class="in-press">
							<table class="table-notice">
								<tr>
									<td colspan="2"><h2>Requests for Public Information</h2></td>
								</tr>
								<tr>
									<td colspan="2">
										Requests for public information must be submitted to the District by one of the approved methods and
										addresses listed below:
									</td>
								</tr>
								<tr>
									<td>By Mail:</td>
									<td>
										Horsepen Bayou Municipal Utility District of Harris County, Texas<br />
										c/o Allen Boone Humphries Robinson LLP<br />
										3200 Southwest Freeway, Suite 2600<br />
										Houston, Texas 77027<br />
									</td>
								</tr>
								<tr>
									<td>By e-mail:</td>
									<td><a href="mailto:hor000@txdistricts.info">hor000@txdistricts.info</a></td>
								</tr>
								<tr>
									<td>In person:</td>
									<td><i>Same as mailing address</i></td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import cElements from "@/components/cElements.vue";
import cElementNotices from "@/components/cElementNotices.vue";

export default {
	name: "Home",
	components: { cElements, cElementNotices },
};
</script>
<style>
.welcome {
	background-image: url(/img/slider-bg4.jpg);
	padding: 0;
	padding-bottom: 30px;
	margin: 0;
	width: 100%;
	color: white;
}
.w-title {
	font-size: 2em;
	text-transform: none;
	color: #fff;
	text-shadow: 2px 2px 3px #444;
	border: none;
	padding-left: 0;
	margin-top: 0;
	margin-bottom: 5px;
	line-height: 1.5em;
}
.bg4 {
	padding: 30px;
}
.w-subtitle {
	font-size: 17.5px;
	font-weight: lighter;
}
.table-notice h2 {
	font-size: 1.25em;
	text-transform: none;

	border: none;
	padding-left: 0;
	margin-top: 0;
	margin-bottom: 5px;
	line-height: 1.5em;
}
.table-notice td {
	vertical-align: top;
}
</style>
