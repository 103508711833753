<template>
  <div>
    <div class="mainmenu-wrapper">
      <div class="container">
        <a class="top-logo">Horsepen Bayou Municipal Utility District</a>
        <div class="menuextras">
          <div class="extras">
            <ul>
              <li>
                <span v-show="!isAuthenticated"><router-link to="/login">LogIn</router-link></span>
                <span v-show="isAuthenticated"><button @click="logOut">LogOut</button></span>
              </li>
            </ul>
          </div>
        </div>
        <nav id="mainmenu" class="mainmenu">
          <ul>
            <li><router-link to="/">Home</router-link></li>
            <li><router-link to="/services">Services</router-link></li>
            <li><router-link to="/conservation">Conservation</router-link></li>
            <li><router-link to="/directors">Directors</router-link></li>
            <li><router-link to="/calendar">Calendar</router-link></li>
            <li><router-link to="/documents">Documents</router-link></li>
            <li><router-link to="/legal">Legal Notice</router-link></li>
          </ul>
        </nav>
      </div>
    </div>
    <router-view />
    <!-- Footer -->
    <div class="footer">
      <div class="container">
        <div class="row">
          <div class="col-footer col-md-3 col-xs-6">
            <h3>Navigate</h3>
            <ul class="no-list-style footer-navigate-section">
              <li><router-link to="/">Home</router-link></li>
              <li><router-link to="/services">Services</router-link></li>
              <li><router-link to="/conservation">Conservation</router-link></li>
              <li><router-link to="/directors">Directors</router-link></li>
              <li><router-link to="/calendar">Calendar</router-link></li>
              <li><router-link to="/documents">Documents</router-link></li>
              <li><router-link to="/legal">Legal Notice</router-link></li>
            </ul>
          </div>

          <div class="col-footer col-md-6 col-xs-6">
            <h3>Contacts</h3>
            <p class="contact-us-details">
              <b>Address:</b> 406 West Grand Parkway South Suite 260, Katy, TX<br />
              <b>Phone:</b> (713) 860-6400<br />
              <b>Email:</b> <a href="mailto:info@municipaldistrictservices.com">info@municipaldistrictservices.com</a>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="footer-copyright">&copy; @DateTime.Now.Year Horsepen Bayou Municipal Utility District</div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div class="legal-notice" v-show="showNotice">
        By using this website you are agreeing to the terms found
        <router-link to="/legal">here</router-link>.
        <a href="#" @click="agreeToNotice">I understand</a>
        <div style="float:right" @click="agreeToNotice">X</div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as _userService from "@/services/userService";

export default {
  name: "vApp",
  props: {},
  components: {},
  data: () => ({
    showNotice: false,
  }),
  methods: {
    logOut() {
      this.loading = true;
      _userService.logOut().then((response) => {});
    },
    agreeToNotice() {
      localStorage.setItem("hp.legal", true);
      this.showNotice = false;
    },
  },
  computed: {
    isAuthenticated: function() {
      return this.$store.getters.isAuthenticated();
    },
  },
  mounted: function() {
    let legalNotice = localStorage.getItem("hp.legal");
    console.log("app|mounted|legalNotie:", legalNotice);
    this.showNotice = !legalNotice || legalNotice !== "true";
  },
};
</script>

<style>
.router-link-active {
  color: #4f8db3 !important;
  font-weight: 600 !important;
}

.legal-notice {
  background-color: rgb(190, 135, 16);
  padding: 15px;
  color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  border: 1px solid gray;
  margin: 5px;
}
.legal-notice a {
  color: white;
  text-decoration: underline;
}
.legal-notice > div {
  cursor: pointer;
}
</style>
