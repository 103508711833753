<template>
  <div class="element-edit-container">
    <div class="card full">
      <div class="card-header">Edit Element</div>
      <div class="card-body">
        <div class="card-text">
          <form>
            <div class="row">
              <div class="col-xs-12 col-sm-3">
                <label for="key" class="form-label">Key</label>
                <input type="text" class="form-control" id="key" v-model="record.ElementKey" />
              </div>
              <div class="col-xs-12 col-sm-3">
                <label for="sortorder" class="form-label">Sort Order</label>
                <input type="number" class="form-control" id="sortorder" v-model.number="record.SortOrder" />
              </div>
              <div class="col-xs-12 col-sm-3">
                <label for="expires" class="form-label">Expires</label>
                <input type="date" class="form-control" id="expires" v-model="record.Expires" />
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-6">
                <label for="title" class="form-label">Title</label>
                <input type="text" class="form-control" id="title" v-model="record.Title" />
              </div>
              <div class="col-xs-12 col-sm-6">
                <label for="subtitle" class="form-label">Sub Title</label>
                <input type="text" class="form-control" id="subtitle" v-model="record.SubTitle" />
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-6">
                <label for="display" class="form-label">Content</label>
                <input type="text" class="form-control" id="display" v-model="record.Content" />
              </div>
              <div class="col-xs-12 col-sm-6">
                <label for="url" class="form-label">Url</label>
                <input type="text" class="form-control" id="url" v-model="record.URL" />
              </div>
            </div>
            <div class="row"></div>
          </form>
        </div>
      </div>
      <div class="card-footer ">
        <div class="row">
          <div class="col-xs-12 col-sm-4">
            <button type="button" class="btn btn-primary" @click="close" :disabled="loading">Cancel</button>
          </div>
          <div class="col-xs-12 col-sm-8 text-right">
            <img v-if="loading" src="/img/wait_circle_25.GIF" alt="Please wait..." />
            <button type="button" class="btn btn-danger" style="margin-right:15px;" v-show="record.Id != '00000000-0000-0000-0000-000000000000'" @click="deleteElement" :disabled="loading">Delete</button>
            <button type="button" class="btn btn-primary" @click="saveElement" :disabled="loading">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as _adminService from "@/services/adminService";
import * as _utility from "@/utility";

const _defaultRecord = {
  Id: _utility.emptyGuid,
  ElementKey: "",
  Title: "",
  SubTitle: "",
  Content: "",
  URL: "",
  Expires: "3030-01-01",
  SortOrder: 0,
};

export default {
  name: "cElementEdit",
  components: {},
  props: ["value", "elementkey"],
  data: () => ({
    record: _defaultRecord,
    loading: false,
  }),
  methods: {
    load() {
      this.loading = true;
      if (this.record.Id == _utility.emptyGuid) {
        this.record = { ..._defaultRecord };
        this.record.ElementKey = this.elementkey;
        this.loading = false;
        return;
      }
      _adminService.getElement(this.record.Id).then((response) => {
        if (!response.Success) {
          alert(response.FailureInformation);
        } else {
          response.Element.Expires = response.Element.Expires.substr(0, response.Element.Expires.indexOf("T"));
          this.record = response.Element;
        }
        this.loading = false;
      });
    },
    deleteElement() {
      this.loading = true;
      _adminService.deleteElement(this.record.Id).then((response) => {
        if (!response.Success) {
          alert(response.FailureInformation);
        } else {
          this.close();
        }
        this.loading = false;
      });
    },
    saveElement() {
      this.loading = true;
      let data = this.record;
      _adminService.saveElement(data).then((response) => {
        if (!response.Success) {
          alert(response.FailureInformation);
        } else {
          this.record.Id = response.ElementId;
          this.close();
        }
        this.loading = false;
      });
    },
    close() {
      this.$emit("input", null);
    },
  },
  computed: {
    isAuthenticated: function() {
      return this.$store.getters.isAuthenticated();
    },
  },
  watch: {
    value: {
      handler: function(newVal, oldVal) {
        if (!newVal) return;
        if (newVal != oldVal) {
          this.record.Id = newVal;
          this.load();
        }
      },
      deep: true,
    },
  },
  mounted: function() {
    this.load();
  },
};
</script>

<style scoped>
.element-edit-container {
  position: fixed;
  background-color: gray;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  padding: 10px;
  text-align: left;
  color: #535b60;
  font-weight: normal;
  text-shadow: none;
  font-size: 14px;
  z-index: 110;
}
.element-edit-container {
}
</style>
