import Vue from "vue";
import Vuex from "vuex";
import * as _config from "@/config";
import * as _utility from "@/utility";

Vue.use(Vuex);

// STATE
const state = {
  user: { name: "", email: null, token: null, role: -1 },
  showWait: false,
  uploadPercent: 0,
  systemMessage: {
    color: "info",
    timeout: _config.defaultMessageTimeout,
    text: "",
    x: _config.defaultMessageX,
    y: _config.defaultMessageY,
  },
};

// MUTATIONS
// NOTE: mutations MUST be synchronous!
const mutations = {
  showWait(state, value) {
    state.showWait = value;
  },
  updateUploadPercent(state, value) {
    state.uploadPercent = value;
  },
  dispatchMessage(state, info) {
    if (info == null) info = { color: "error", timeout: 6000, text: "!!Invalid info sent to dispatch Message!!", x: _config.defaultMessageX, y: _config.defaultMessageY };
    if (info.color == null) info.color = "error";
    if (info.timeout == null) info.timeout = _config.defaultMessageTimeout;
    if (info.x === undefined) info.x = _config.defaultMessageX;
    if (info.y === undefined) info.y = _config.defaultMessageY;
    if (info.text == null) info.text = "!!No message text provided to message dispatch!!";
    state.systemMessage = info;
  },
};

// ACTIONS
// Actions can be async
const actions = {
  logIn({ state }, info) {
    return new Promise((resolve) => {
      if (info == null || info.length == 0) {
        resolve(false);
      }
      state.user = {
        email: info.email,
        token: info.token,
        name: info.name,
        role: info.role,
      };
      localStorage.setItem(_config.storageUserKey, JSON.stringify(state.user));
      resolve(true);
    });
  },
  logOut({ state }) {
    localStorage.removeItem(_config.storageUserKey);
    state.user = { name: null, email: null, token: null, role: null };
  },
  refreshToken({ state }, token) {
    state.user.token = token;
    let ls = localStorage.getItem(_config.storageUserKey);
    if (ls != null) {
      ls.token = token;
      localStorage.setItem(_config.storageUserKey, ls);
    }
  },
  loadStore({ state }) {
    return new Promise((resolve) => {
      let ls = localStorage.getItem(_config.storageUserKey);
      if (ls != null) {
        state.user = JSON.parse(ls);
        resolve(true);
      } else resolve(false);
    });
  },
};

const getters = {
  email: (state) => (state.user.email === null ? "" : state.user.email),
  token: (state) => (state.user.token === null ? "" : state.user.token),
  role: (state) => (state.user.role === null ? "" : state.user.role),
  isAuthenticated: (state) => () => {
    if (state.user.token !== null) {
      return true;
    } else {
      let ls = localStorage.getItem(_config.storageUserKey);
      if (ls != null) {
        state.user = JSON.parse(ls);
        return state.user.token != null;
      } else return false;
    }
  },
  showWait: (state) => state.showWait,
  uploadPercent: (state) => state.uploadPercent,
  systemMessage: (state) => state.systemMessage,
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
});
