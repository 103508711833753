import * as _core from "./coreService";
import * as _config from "@/config";
import store from "../store";

//---------------------------------------------------------------
//Eelement Support
//---------------------------------------------------------------
export function deleteElement(id) {
  return new Promise((resolve) => {
    let url = _config.urlAdminService + "DeleteElement/";
    let data = { ElementId: id };
    _core.fetchPost(url, data, true).then((response) => {
      resolve(response);
    });
  });
}

export function getElement(id) {
  return new Promise((resolve) => {
    let url = _config.urlAdminService + "GetElement/";
    let data = { ElementId: id };
    _core.fetchGet(url, data, true).then((response) => {
      resolve(response);
    });
  });
}

export function saveElement(form) {
  return new Promise((resolve) => {
    let url = _config.urlAdminService + "SaveElement/";
    let data = { Element: form };
    _core.fetchPost(url, data, true).then((response) => {
      resolve(response);
    });
  });
}

//---------------------------------------------------------------
//Event Support
//---------------------------------------------------------------
export function deleteEvent(id) {
  return new Promise((resolve) => {
    let url = _config.urlAdminService + "DeleteEvent/";
    let data = { EventId: id };
    _core.fetchPost(url, data, true).then((response) => {
      resolve(response);
    });
  });
}

export function getEvent(id) {
  return new Promise((resolve) => {
    let url = _config.urlAdminService + "GetEvent/";
    let data = { EventId: id };
    _core.fetchGet(url, data, true).then((response) => {
      resolve(response);
    });
  });
}

export function saveEvent(form) {
  return new Promise((resolve) => {
    let url = _config.urlAdminService + "SaveEvent/";
    let data = { Event: form };
    _core.fetchPost(url, data, true).then((response) => {
      resolve(response);
    });
  });
}
