import store from "@/store";
import router from "@/router";
import * as _config from "@/config";

//https://javascript.info/fetch
export function fetchGet(url, data, showWait) {
  return new Promise((resolve, reject) => {
    //automatically append the token if it exists..
    data.Token = store.getters.token;
    //build the querystring
    let queryString = "";
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        queryString += key + "=" + data[key] + "&";
      }
    }
    if (queryString.length > 0) {
      url += "?" + queryString;
    }
    if (showWait) store.commit("showWait", true);
    fetch(url, {
      method: "GET",
    })
      .then((data) => data.json())
      .then((response) => {
        if (showWait) store.commit("showWait", false);
        if (!response.Success) {
          store.commit("dispatchMessage", {
            color: response.DisplayStyle == 2 ? "warning" : "danger",
            text: response.FailureInformation,
          });
        }
        return resolve(response);
      })
      .catch((error) => {
        if (showWait) store.commit("showWait", false);
        let response = {
          Success: false,
          FailureInformation: "There was a problem calling the service",
          DisplayStyle: 2,
        };
        store.commit("dispatchMessage", {
          color: "danger",
          text: response.FailureInformation,
        });
        console.error("coreService fetch error:");
        console.error(error);
        return resolve(response);
      });
  });
}

export function fetchPost(url, data, showWait) {
  return new Promise((resolve, reject) => {
    //automatically append the token if it exists..
    data.Token = store.getters.token;
    //If we use .Net WebAPI we need to put data in a form,
    //but if we use CODE Framework WebAPI we need to send
    //data as a JSON payload..
    //let formData = createFormFromData(data);
    if (showWait) store.commit("showWait", true);
    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((data) => data.json())
      .then((response) => {
        if (showWait) store.commit("showWait", false);
        if (!response.Success) {
          store.commit("dispatchMessage", {
            color: response.DisplayStyle == 2 ? "warning" : "danger",
            text: response.FailureInformation,
          });
        }
        return resolve(response);
      })
      .catch((error) => {
        if (showWait) store.commit("showWait", false);
        console.error("coreService fetch error:");
        console.error(error);
        return reject({
          Success: false,
          FailureInformation: "There was a problem calling the service",
          DisplayStyle: 2,
        });
      });
  });
}

//Nested form data requires recursive processing...
//this was found at https://gist.github.com/ghinda/8442a57f22099bdb2e34
//and works for at least one level of nesting...
function createFormFromData(obj, form, namespace) {
  let fd = form || new FormData();
  let formKey;

  for (let property in obj) {
    if (obj.hasOwnProperty(property) && obj[property]) {
      if (namespace) {
        formKey = namespace + "[" + property + "]";
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File, use recursivity.
      if (obj[property] instanceof Date) {
        fd.append(formKey, obj[property].toISOString());
      } else if (typeof obj[property] === "object" && !(obj[property] instanceof File)) {
        createFormFromData(obj[property], fd, formKey);
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property]);
      }
    }
  }

  return fd;
}
