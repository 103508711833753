let apiUrl = "../api/";
if (location.port) {
  apiUrl = "https://localhost:44337/api/";
}
//Local Storage keys
export const storageBaseKey = "hpb.vue";
export const storageUserKey = storageBaseKey + ".user";
export const storageAppIdKey = storageBaseKey + ".appid";

//System message defaults
export const defaultMessageX = "right";
export const defaultMessageY = "top";
export const defaultMessageTimeout = 6000;

//Service Endpoints
export const urlPublicService = apiUrl + "public/";
export const urlUserService = apiUrl + "user/";
export const urlAdminService = apiUrl + "admin/";
