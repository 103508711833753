import mapper from "@/utility/mapper";
import * as fileSaver from "file-saver";

export const emptyGuid = "00000000-0000-0000-0000-000000000000";

export function newGuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function getTodayString() {
  let d = new Date();
  let sd = d.getFullYear() + "-";
  d.getMonth + 1 > 9 ? (sd += d.getMonth() + 1) : (sd += "0" + (d.getMonth() + 1));
  sd += "-" + d.getDate();
  return sd;
}

export function b64toBlob(b64Data, contentType, sliceSize) {
  //console.log("b64toBlob: " + contentType);
  //console.log("b64toBlob: " + b64Data);
  contentType = contentType || "";
  sliceSize = sliceSize || 512;
  var byteCharacters = atob(b64Data);
  var byteArrays = [];
  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);
    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function mimeTypeToFileExtension(mimeType) {
  //https://stackoverflow.com/questions/4212861/what-is-a-correct-mime-type-for-docx-pptx-etc

  //application/pdf
  if (mimeType.indexOf("pdf") > -1) return "pdf";
  //application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
  if (mimeType.indexOf("spreadsheetml.sheet") > -1) return "xlsx";
  if (mimeType.indexOf("xlsx") > -1) return "xlsx";
  //application/vnd.ms-excel
  if (mimeType.indexOf("excel") > -1) return "xls";
}

export function saveFile(fileName, fileBytes, mimeType) {
  let decodedFile = b64toBlob(fileBytes, mimeType, 512);
  let blob = new Blob([decodedFile], {
    type: mimeTypeToFileExtension(mimeType),
  });
  fileSaver.saveAs(blob, fileName);
}

export { mapper };
