<template>
  <div>
    <cElementEdit v-show="selectedElementId" v-model="selectedElementId" :elementkey="elementkey" />
    <ul>
      <li v-for="r in records" :key="r.Id">
        <span v-if="isAuthenticated" @click="editElement(r.Id)">Edit</span>
        <a :href="r.URL" target="_blank">
          {{ r.Content }}
        </a>
      </li>
    </ul>
    <button v-show="isAuthenticated" class="btn" style="margin-top:10px;" @click="addElement">Add</button>
    <img v-if="loading" src="/img/wait_circle_25.GIF" alt="Please wait..." />
  </div>
</template>

<script>
import * as _publicService from "@/services/publicService";
import * as _utility from "@/utility";
import cElementEdit from "@/components/cElementEdit.vue";

export default {
  name: "cElements",
  components: { cElementEdit },
  props: ["elementkey"],
  data: () => ({
    records: [],
    loading: false,
    selectedElementId: null,
  }),
  methods: {
    load() {
      this.loading = true;
      _publicService.getRecordsForElement(this.elementkey).then((response) => {
        if (!response.Success) {
          alert("there was a problem getting recrods for " + this.key);
        } else {
          this.records = response.Records;
        }
        this.loading = false;
      });
    },
    addElement() {
      if (!this.isAuthenticated) return;
      this.selectedElementId = _utility.emptyGuid;
    },
    editElement(id) {
      if (!this.isAuthenticated) return;
      this.selectedElementId = id;
    },
  },
  computed: {
    isAuthenticated: function() {
      return this.$store.getters.isAuthenticated();
    },
  },
  watch: {
    selectedElementId: function(newVal, oldVal) {
      if (oldVal && newVal == null) this.load();
    },
  },
  mounted: function() {
    this.load();
  },
};
</script>
