import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () => import(/* webpackChunkName: "site" */ "../views/About.vue"),
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: () => import(/* webpackChunkName: "site" */ "../views/Calendar.vue"),
  },
  {
    path: "/conservation",
    name: "Conservation",
    component: () => import(/* webpackChunkName: "site" */ "../views/Conservation.vue"),
  },
  {
    path: "/directors",
    name: "Directors",
    component: () => import(/* webpackChunkName: "site" */ "../views/Directors.vue"),
  },
  {
    path: "/documents",
    name: "Documents",
    component: () => import(/* webpackChunkName: "site" */ "../views/Documents.vue"),
  },
  {
    path: "/services",
    name: "Services",
    component: () => import(/* webpackChunkName: "site" */ "../views/Services.vue"),
  },
  {
    path: "/legal",
    name: "Legal",
    component: () => import(/* webpackChunkName: "site" */ "../views/LegalNotice.vue"),
  },
  {
    path: "/login",
    name: "LogIn",
    component: () => import(/* webpackChunkName: "admin" */ "../views/LogIn.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
