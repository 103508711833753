import Vue from "vue";
import dayjs from "dayjs";

Vue.filter("toMMDDYY", function(value) {
  return dayjs(value).format("MM/DD/YYYY");
});

Vue.filter("toHHMMAM", function(value) {
  return dayjs(value).format("h:mm A");
});
Vue.filter("toDD", function(value) {
  return dayjs(value).format("DD");
});
Vue.filter("toMonthName", function(value) {
  return dayjs(value).format("MMMM");
});
Vue.filter("userProfileImg", function(value) {
  if (value == null || value.length === 0) return "/assets/images/default-user.png";
  return value;
});

Vue.filter("roleIdToString", function(value) {
  if (value == null || value.length === 0) return "";
  if (value == 0) return "User";
  if (value == 1) return "Manager";
  if (value == 2) return "Admin";
  if (value == 99) return "Site Admin";
});

Vue.filter("boolToYesNo", function(value) {
  if (value == null || value.length === 0) return "-";
  return value ? "Yes" : "No";
});

Vue.filter("boolToActive", function(value) {
  if (value == null || value.length === 0) return "-";
  return value ? "Active" : "Inactive";
});
