import * as _core from "./coreService";
import * as _config from "@/config";
import store from "../store";

export function logIn(email, password) {
  return new Promise((resolve) => {
    let url = _config.urlUserService + "LogIn/";
    let data = { Email: email, Password: password };
    _core.fetchPost(url, data, true).then((response) => {
      if (response.Success) {
        store
          .dispatch("logIn", {
            email: email,
            token: response.Token,
            name: response.UserName,
            role: 0,
          })
          .then((dispatchSuccess) => {
            resolve(response);
          });
      } else {
        resolve(response);
      }
    });
  });
}

export function logOut() {
  return new Promise((resolve) => {
    store.dispatch("logOut").then((r) => {});

    //let url = _config.urlUserService + "LogOut/";
    //let data = {};
    //_core.fetchPost(url, data, true).then((response) => {
    //  if (response.Success) {
    //    store.dispatch("logOut").then((dispatchSuccess) => {
    //      resolve(response);
    //    });
    //  } else {
    //    resolve(response);
    //  }
    //});
  });
}

export function updatePassword(existingPassword, newPassword, confirmPassword) {
  return new Promise((resolve) => {
    let url = _config.urlUserService + "UpdatePassword/";
    let data = { ExistingPassword: existingPassword, NewPassword: newPassword, ConfirmPassword: confirmPassword };
    _core.fetchPost(url, data, true).then((response) => {
      resolve(response);
    });
  });
}
