import * as _core from "./coreService";
import * as _config from "@/config";

export function getRecordsForElement(key) {
  return new Promise((resolve) => {
    let url = _config.urlPublicService + "GetRecordsForElement/";
    let data = { Key: key };
    _core.fetchGet(url, data, true).then((response) => {
      resolve(response);
    });
  });
}

export function getEvents(year) {
  return new Promise((resolve) => {
    let url = _config.urlPublicService + "GetEvents/";
    let data = { Year: year };
    _core.fetchGet(url, data, true).then((response) => {
      resolve(response);
    });
  });
}
